import React from "react"
import DocumentMeta from 'react-document-meta'
import { BreadCrumb } from '../../components'
import { SiteTitle } from './common'

const Howto = () =>
{
  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: 'プライバシーポリシー',
      href: '/privacy_policy'
    }
  ]

  return(
    <DocumentMeta {...meta}>
      <div id="privacy_policy_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="privacy_policy_content">
          <h1>プライバシーポリシー</h1>
          <section className="introduction">
            <div className="text_area">
              <p>
              長崎西彼農業協同組合（以下「当組合」といいます。）は、組合員・利用者等の皆様の個人情報を正しく取扱うことが当組合の事業活動の基本であり社会的責務であることを認識 し、以下の方針を遵守することを誓約します。
              </p>
            </div>
          </section>
          <section>
            <h2>１．関連法令等の遵守 </h2>
            <p className="text_area">
              当組合は、個人情報を適正に取扱うために、「個人情報の保護に関する法律」（以下「保 護法」といいます。）その他、個人情報保護に関する関係諸法令及び個人情報保護委員 会のガイドライン等に定められた義務を誠実に遵守します。 個人情報とは、保護法第２条第１項、第２項に規定する、生存する個人に関する情報 で、特定の個人を識別できるものをいい、以下も同様とします。 また、当組合は、特定個人情報を適正に取扱うために、「行政手続における特定の個人 を識別するための番号の利用等に関する法律」（以下「番号利用法」といいます。）その 他、特定個人情報の適正な取扱いに関する関係諸法令及びガイドライン等に定められた 義務を誠実に遵守します。 特定個人情報とは、番号利用法２条第８項に規定する、個人番号をその内容に含む個 人情報をいい、以下も同様とします。
            </p>
          </section>
          <section>
            <h2>２．利用目的</h2>
            <p className="text_area">
              当組合は、個人情報の取扱いにおいて、利用目的をできる限り特定したうえ、あらか じめご本人の同意を得た場合及び法令により例外として扱われるべき場合を除き、その 利用目的の達成に必要な範囲内でのみ個人情報を利用します。ただし、特定個人情報に おいては、利用目的を特定し、ご本人の同意の有無に関わらず、利用目的の範囲を超え た利用は行いません。 ご本人とは、個人情報によって識別される特定の個人をいい、以下も同様とします。 利用目的は、法令により例外として扱われるべき場合を除き、あらかじめ公表するか、 取得後速やかにご本人に通知し、または公表します。ただし、ご本人から直接書面で取 得する場合には、あらかじめ明示します。
            </p>
          </section>
          <section>
            <h2>３．適正取得</h2>
            <p className="text_area">
              当組合は、個人情報を適正に取扱うために、「個人情報の保護に関する法律」（以下「保 護法」といいます。）その他、個人情報保護に関する関係諸法令及び個人情報保護委員 会のガイドライン等に定められた義務を誠実に遵守します。 個人情報とは、保護法第２条第１項、第２項に規定する、生存する個人に関する情報 で、特定の個人を識別できるものをいい、以下も同様とします。 また、当組合は、特定個人情報を適正に取扱うために、「行政手続における特定の個人 を識別するための番号の利用等に関する法律」（以下「番号利用法」といいます。）その 他、特定個人情報の適正な取扱いに関する関係諸法令及びガイドライン等に定められた 義務を誠実に遵守します。 特定個人情報とは、番号利用法２条第８項に規定する、個人番号をその内容に含む個 人情報をいい、以下も同様とします。
            </p>
          </section>
          <section>
            <h2>４．安全管理措置</h2>
            <p className="text_area">
              当組合は、取扱う個人データ及び特定個人情報を利用目的の範囲内で正確・最新の内 容に保つよう努め、また安全管理のために必要・適切な措置を講じ役職員及び委託先を 適正に監督します。 個人データとは、保護法第２条第６項が規定する、個人情報データベース等（保護法 第２条第４項）を構成する個人情報をいい、以下同様とします。
            </p>
          </section>
          <section>
            <h2>５．匿名加工情報の取扱い</h2>
            <p className="text_area">
              当組合は、匿名加工情報（保護法第２条第９項）の取扱いに関して消費者の安心感・ 信頼感を得られるよう、保護法の規定に従うほか、個人情報保護委員会のガイドライン、 認定個人情報保護団体の個人情報保護方針に則して、パーソナルデータの適正かつ効果 的な活用を推進いたします。
            </p>
          </section>
          <section>
            <h2>６．第三者提供の制限</h2>
            <p className="text_area">
              当組合は、法令により例外として扱われるべき場合を除き、あらかじめご本人の同意 を得ることなく、個人データを第三者に提供しません。 また、当組合は、番号利用法 19 条各号により例外として扱われるべき場合を除き、 ご本人の同意の有無に関わらず、特定個人情報を第三者に提供しません。
            </p>
          </section>
          <section>
            <h2>７．機微（センシティブ）情報の取り扱い</h2>
            <p className="text_area">
              当組合は、ご本人の機微（センシティブ）情報（要配慮個人情報並びに労働組合への 加盟、門地・本籍地、保健医療等に関する情報）については、法令等に基づく場合や業 務遂行上必要な範囲においてご本人の同意をいただいた場合等を除き、取得・利用・第 三者提供はいたしません。
            </p>
          </section>
          <section>
            <h2>８．開示・訂正等</h2>
            <p className="text_area">
              当組合は、保有個人データにつき、法令に基づきご本人からの開示、訂正等に応じま す。 保有個人データとは、保護法第２条第７項に規定するデータをいいます。
            </p>
          </section>
          <section>
            <h2>９．苦情窓口</h2>
            <p className="text_area">
              当組合は、個人情報につき、ご本人からの質問・苦情に対し迅速かつ適切に取り組み、 そのための内部体制の整備に努めます。
            </p>
          </section>
          <section>
            <h2>１０．継続的改善</h2>
            <p className="text_area">
              当組合は、個人情報について、適正な内部監査を実施するなどして、本保護方針の継 続的な改善に努めます
            </p>
          </section>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default Howto