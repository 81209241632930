import React, { useCallback, useEffect, useState } from 'react'
import { changeCategory, changeKeyword } from '../../reducks/items/operations'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RouteDir } from "../../common"


const Sidebar = () =>
{
  const dispatch = useDispatch();

  const keyword = useSelector(state => state.items.selectKeyword)
  //検索商品名の入力
  const [searchNameValue, setSearchName] = useState(keyword)
  const inputSearchName = useCallback((event) =>
  {
    setSearchName(event.target.value)
  }, [setSearchName])

  useEffect(()=>{
    setSearchName(keyword)
  },[keyword])

  const menuListFruitStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_fruit.png')`
  }

  const menuListMeatStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_meat.png')`
  }

  const menuListVegetableStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_vegetable.png')`
  }

  const menuListProcessedGoodsStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_Processed_goods.png')`
  }

  const menuListFlowerArrangementStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_flower_arrangement.png')`,
    backgroundSize: '25px',
    backgroundPosition: 'right 37px center'
  }

  const menuListOtherStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/menu_icon_other.png')`
  }

  const searchInputStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/icon_search.png')`
  }

  const selectItemListByCategory = (id)=>
  {
    setSearchName('')
    // dispatch(changeCategory(id))
    dispatch(push(RouteDir + '/item/list?category=' + id))
  }

  const selectItemListByKeyword = (searchNameValue)=>
  {
    // dispatch(changeKeyword(searchNameValue))
    dispatch(push(RouteDir + '/item/list?keyword=' + searchNameValue))
  }


  return(
    <aside className={'sidebar'}>
      <div className="search_area">
        <input
          type = "text"
          name = "search_name"
          style = {searchInputStyle}
          value = {searchNameValue}
          onChange = {inputSearchName}
          placeholder = {'キーワード検索'}
        />
        <button onClick={()=>selectItemListByKeyword(searchNameValue)}>検索</button>
      </div>
      <div className="menu_list">
        <h2>カテゴリ一覧</h2>
        <ul>
          <li style={menuListFruitStyle} onClick={()=>selectItemListByCategory(1)}>
            <span>果物</span>
          </li>
          <li style={menuListMeatStyle} onClick={()=>selectItemListByCategory(2)}>
            <span>お肉</span>
          </li>
          <li style={menuListVegetableStyle} onClick={()=>selectItemListByCategory(3)}>
            <span>野菜</span>
          </li>
          <li style={menuListProcessedGoodsStyle} onClick={()=>selectItemListByCategory(4)}>
            <span>加工品</span>
          </li>
          <li style={menuListFlowerArrangementStyle} onClick={()=>selectItemListByCategory(5)}>
            <span>生花</span>
          </li>
          <li style={menuListOtherStyle} onClick={()=>selectItemListByCategory(6)}>
            <span>その他</span>
          </li>
        </ul>
      </div>
    </aside>
  )
}

export default Sidebar