import React, { useEffect, useCallback, useState } from "react"
import DocumentMeta from 'react-document-meta'
import { insertOrderTemp } from '../../reducks/orders/operations'
import { useDispatch, useSelector } from 'react-redux'
import { BreadCrumb, OrderFlow } from '../../components'
import { SiteTitle } from './common'
import axios from 'axios'
import { push } from "connected-react-router"
import {
  HomeUrl,
  postUrl,
  merchantId,
  merchantName,
  copyRight,
  ApiDir,
  RouteDir,
} from '../../common'


const OrderConfirm = () =>
{
  const dispatch = useDispatch()

  //注文情報
  const orders = useSelector(state => state.orders.list)
  const payType = useSelector(state => state.orders.payType)

  //商品合計
  const [totalItemPrice, setTotalItemPrice] = useState(0)

  //送料合計
  const [totalPostage, setTotalPostage] = useState(0)

  //手数料
  const [commission, setCommission] = useState(0)

  //合計
  const [totalPrice, setTotalPrice] = useState(0)

  //決済ID
  const [paymentId, setPaymentId] = useState('')

  //ハッシュ値
  const [hash, setHash] = useState('')

  //決済IDの生成
  const getDateTime = ()=>
  {
    const toDoubleDigites = (num)=>
    {
      let number = String(num)
      if(number.length===1)
      {
        number = '0' + number
      }
      return number
    }
    const date = new Date()
    const datetime = String(date.getFullYear())+toDoubleDigites(date.getMonth()+1)+toDoubleDigites(date.getDate())+toDoubleDigites(date.getHours())+toDoubleDigites(date.getMinutes())+toDoubleDigites(date.getSeconds())
    return datetime
  }

  useEffect(()=>{
    setTotalItemPrice(0)
    const orderCount = orders.length
    //商品合計の計算
    const calcOrderItemPrice = () =>
    {
      let totalItemPriceRes = 0
      for(let i=0;i<orderCount;i++)
      {
        const itemCount = orders[i].item_list.length
        let orderItemPriceValue = 0
        const calcItemPrice = () =>
        {
          for(let k=0;k<itemCount;k++)
          {
            const itemTotal = Number(orders[i].item_list[k].number)*Number(Math.floor(Number(orders[i].item_list[k].price)*(1+(Number(orders[i].item_list[k].tax_value)/100))))
            orderItemPriceValue = orderItemPriceValue +itemTotal
          }
          return orderItemPriceValue
        }
        totalItemPriceRes = totalItemPriceRes + calcItemPrice()
      }
      return totalItemPriceRes
    }
    //商品合計のセット
    setTotalItemPrice(calcOrderItemPrice())

    //送料合計の計算
    const calcOrderPostage = () =>
    {
      let totalPostageRes = 0
      for(let i=0;i<orderCount;i++)
      {
        const itemCount = orders[i].item_list.length
        for(let k=0;k<itemCount;k++)
        {
          totalPostageRes = totalPostageRes + Number(orders[i].item_list[k].postage)
        }
      }
      return totalPostageRes
    }
    setTotalPostage(calcOrderPostage())

    //合計の計算
    setTotalPrice(totalItemPrice + totalPostage + commission)

    setPaymentId(getDateTime())
    console.log(getDateTime())

    //ハッシュ値の生成
    const formData = new FormData()
    formData.append('trading_id', getDateTime())  //決済ID
    console.log(getDateTime())
    if(payType.id==='1')
    {
      formData.append('payment_type', '03')  //支払い種別コンビニ
    }
    else if(payType.id==='2')
    {
      formData.append('payment_type', '02')  //支払い種別クレジット決済
    }
    else
    {
      formData.append('payment_type', '02,03')  //支払い種別
    }
    formData.append('id', totalPrice)  //請求金額
    formData.append('seq_merchant_id', merchantId)  //マーチャントID
    formData.append('merchant_name', merchantName)  //マーチャントName
    // formData.append('mail_send_flg_success', 1)  //決済申込が正常処理時のメール送信
    // formData.append('mail_send_flg_failure', 1)  //決済申込時に処理異常発生時のメール送信

    axios.post(ApiDir+'/generateHash.php',formData)
    .then(function(response){
      console.log(response.data.replace(/\r?\n/g,""))
      setHash(response.data.replace(/\r?\n/g,""))
    })
    .catch(function(error){
      console.log(error)
      return
    })

  },[orders, totalItemPrice, totalPostage, totalPrice, commission, paymentId, payType.id])

  const OrderDecideBtn = () =>
  {
    console.log(paymentId)
    dispatch(insertOrderTemp(orders, payType, totalPrice, paymentId))
    const form = document.createElement('form');
    form.action = postUrl
    form.method = 'POST'
    document.body.append(form)
    form.addEventListener('formdata', (e)=>{
      const formData = e.formData
      formData.set('trading_id', paymentId)
      if(payType.id==='1')
      {
        formData.set('payment_type', '03')
      }
      else if(payType.id==='2')
      {
        formData.set('payment_type', '02')
      }
      else
      {
        formData.set('payment_type', '02,03')
      }
      formData.set('id', totalPrice)
      formData.set('seq_merchant_id', merchantId)
      formData.set('merchant_name', merchantName)
      formData.set('payment_detail', '商品代')
      formData.set('payment_detail_kana', 'ｼｮｳﾋﾝﾀﾞｲ')
      formData.set('isbtob', '0')
      formData.set('return_url', 'http://localhost/seihi_ec_test/api/returnPayment.php?trading_id='+paymentId)
      formData.set('stop_return_url', HomeUrl)
      formData.set('copy_right', copyRight)
      if(payType.id==='2')
      {
        formData.set('finish_disable', '1')
      }
      formData.set('mail_send_flg_success', '1')
      formData.set('mail_send_flg_failure', '1')
      formData.set('hc', hash)
    })
    form.submit()
  }


  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: '買い物カゴ',
      href: '/mypage/cart_list'
    }
  ]

  return(
    <DocumentMeta {...meta}>
      <div id="order_confirm_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="order_confirm_content">
          <h1>ご入力内容の確認</h1>
          <OrderFlow
            activeKey = {3}
          />
          <section className="list_type_4">
            <div className="order_info_list">
              <div className="order_card_box">
                <h2>お届け先</h2>
                {Array.isArray(orders) && orders.map((order, i)=>(
                  <div key={i} className="order_card">
                    <dl>
                      <dt className="post_title">お届け先{i+1}</dt>
                      <dd>
                        <p className="post_name">{order.myhouse_flag==='1'?'ご自宅':order.delivery_family_name+' '+order.delivery_first_name+' 様'}</p>
                        <p>〒{(()=>{
                          const postalCode = order.delivery_postal_code
                          return (postalCode.slice(0,3) + '-' + postalCode.slice(3))
                        })()}</p>
                        <p>{order.delivery_address}</p>
                        <p>{order.delivery_telnumber}</p>
                      </dd>
                      <dt>お届け商品</dt>
                      <dd>
                        {Array.isArray(order.item_list) && order.item_list.map((item, i)=>(
                          <p key={i} className="item_info">
                            <span className="item_name">{item.name}</span>
                            <span className="standard">{item.standard}</span>
                            <span className="number">数量 {item.number}</span>
                            <span className="price">金額 ¥{(()=>{
                              const itemPrice = Number(item.number)*Number(Math.floor(Number(item.price)*(1+(Number(item.tax_value)/100))))
                              return(itemPrice.toLocaleString())
                            })()}</span>
                          </p>
                        ))}
                      </dd>
                      <dt>納品書</dt>
                      <dd>
                        {order.delivery_slip_flag==='1'?'必要':'不要'}
                      </dd>
                    </dl>
                  </div>
                ))}
              </div>
              <div className="pay_type_box">
                <h2>お支払方法</h2>
                <dl>
                  <dt>お支払方法</dt>
                  <dd>
                    {payType.name}
                  </dd>
                </dl>
              </div>
              <div className="total_price_box">
                <h2>お支払金額</h2>
                <dl>
                  <dt>商品合計</dt>
                  <dd>¥{totalItemPrice.toLocaleString()}</dd>
                </dl>
                <dl>
                  <dt>送料</dt>
                  <dd>¥{totalPostage.toLocaleString()}</dd>
                </dl>
                <dl>
                  <dt>手数料</dt>
                  <dd>¥{commission.toLocaleString()}</dd>
                </dl>
                <dl>
                  <dt>合計</dt>
                  <dd className="total_price">¥{totalPrice.toLocaleString()}</dd>
                </dl>
              </div>
            </div>
            <div className="btn_area">
              <button className="order_info_link_btn" onClick={()=>dispatch(push(RouteDir+'/mypage/order_info'))}>支払い方法選択へ戻る</button>
              <button className="order_decide_btn" onClick={()=>OrderDecideBtn()}>ご注文を完了する</button>
            </div>
          </section>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default OrderConfirm