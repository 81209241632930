import React, { useEffect, useCallback, useState } from "react"
import DocumentMeta from 'react-document-meta'
import { selectOrderPostAddressList, insertOrderPostAddress } from '../../reducks/orders/operations'
import { useDispatch } from 'react-redux'
import { BreadCrumb, OrderFlow, AddPostAddressForm } from '../../components'
import { SiteTitle } from './common'
import axios from 'axios'
import { ApiDir, RouteDir } from '../../common'
import { push } from "connected-react-router"

const CartList = () =>
{
  const dispatch = useDispatch()

  const [forms, setForms] = useState([{number:1}])
  const [formCount, setFormCount] = useState(1)

  useEffect(()=>{
    dispatch(selectOrderPostAddressList())
  },[dispatch])

  const insertMyhouseAddress = () =>
  {
    const formElement = document.getElementById('insert_form_1')
    formElement.myhouse_flag_0.value = 1
    //自宅情報の取得
    let params = new URLSearchParams()
    params.append('formkey','selectkey')
    axios.post(ApiDir+'/selectMyMemberInfo.php',params)
    .then(function(response){
      console.log(response.data)
      formElement.myhouse_flag_0.value = 1
      formElement.family_name_0.value = response.data[0].family_name
      formElement.first_name_0.value = response.data[0].first_name
      formElement.family_name_furigana_0.value = response.data[0].family_name_furigana
      formElement.first_name_furigana_0.value = response.data[0].first_name_furigana
      formElement.postal_code_0.value = response.data[0].postal_code
      formElement.address_0.value = response.data[0].address
      formElement.telnumber_0.value = response.data[0].telnumber
    })
    .catch(function(error){
      console.log(error)
      return
    })

  }

  const AddpostAddressBtn = ()=>
  {
    const formElements = document.forms
    const formElementsCount = formElements.length
    for(let i=0;i<formElementsCount;i++)
    {
      const formData = new FormData(formElements[i])
      const familyName = formData.get('family_name_'+i)
      const firstName = formData.get('first_name_'+i)
      const familyNameFurigana = formData.get('family_name_furigana_'+i)
      const firstNameFurigana = formData.get('first_name_furigana_'+i)
      const postalCode = formData.get('postal_code_'+i)
      const address = formData.get('address_'+i)
      const telnumber = formData.get('telnumber_'+i)
      const deliverySlipFlag = formData.get('delivery_slip_flag_'+i)
      const myhouseFlag = formData.get('myhouse_flag_'+i)
      if(familyName !== '')
      {
        if(familyName === '' || firstName === '' || familyNameFurigana === '' || firstNameFurigana === '' || postalCode === '' || address === '' || telnumber === '')
        {
          window.alert('必須項目を入力してください。')
          return false
        }
        else
        {
          //formデータの作り直し
          const sendFormData = new FormData()
          sendFormData.append('family_name', familyName)
          sendFormData.append('first_name', firstName)
          sendFormData.append('family_name_furigana', familyNameFurigana)
          sendFormData.append('first_name_furigana', firstNameFurigana)
          sendFormData.append('postal_code', postalCode)
          sendFormData.append('address', address)
          sendFormData.append('telnumber', telnumber)
          sendFormData.append('delivery_slip_flag', deliverySlipFlag)
          sendFormData.append('myhouse_flag', myhouseFlag)
          dispatch(insertOrderPostAddress(sendFormData))
        }
      }
    }
  }

  const addFormBtn = ()=>
  {
    const formElement = document.getElementById('insert_form_1')
    if(formElement.myhouse_flag_0.value === '1')
    {
      setFormCount(formCount+1)
      setForms([...forms,{number:formCount+1}])
      insertMyhouseAddress()
    }
    else
    {
      setFormCount(formCount+1)
      setForms([...forms,{number:formCount+1}])
    }
  }

  const InsertButtonStyle =
  {
    backgroundImage: `URL('${process.env.PUBLIC_URL}/images/insert_icon.png')`
  }

  const meta =
  {
    title: SiteTitle,
  }

  const Floors =
  [
    {
      name: '買い物カゴ',
      href: '/mypage/cart_list'
    }
  ]


  return(
    <DocumentMeta {...meta}>
      <div id="add_order_post_address_page">
        <BreadCrumb
          floors = { Floors }
        />
        <main className="add_order_post_address_content">
          <h1>お届け先情報の追加</h1>
          <OrderFlow
            activeKey = {1}
          />
          <p className="introduction">
            「ご自宅の住所を入力する」を押すと登録されているご自宅の情報が入力できます。<br/>
            「お届けリストより入力する」を押すと登録した「お届けリスト」からお届け先を選択できます。<br/>
            会員情報に住所の登録がお済でない方、ご自宅以外にお届けされたい方は、下の入力蘭に必要項目をご入力ください。
          </p>
          <div className="add_address_btn_area">
            <button onClick={()=>insertMyhouseAddress()}>ご自宅の住所を入力する</button>
            <button onClick={()=>dispatch(push(RouteDir+'/mypage/post_address_list'))}>お届けリストより入力する</button>
          </div>
          <p className="introduction">お客様の情報をご入力ください。</p>
          <section className="form_type_1">
            {Array.isArray(forms) && forms.map((form,i)=>(
              <AddPostAddressForm
                key = {i}
                index = {i}
                number = {form.number}
              />
            ))}
            <div className="add_form_btn_area">
              <button  style={InsertButtonStyle} onClick={()=>addFormBtn()}>お届け先をさらに追加する</button>
            </div>
          </section>
          <div className="btn_area">
            <button className="order_post_info_link_btn" onClick={()=>dispatch(push(RouteDir+'/mypage/order_post_info'))}>お届け先の選択に戻る</button>
            <button className='add_btn' onClick={()=>AddpostAddressBtn()}>決定</button>
          </div>
        </main>
      </div>
    </DocumentMeta>
  )
}

export default CartList