import React, { useCallback, useState } from 'react'
import {useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import { RouteDir, JsDir } from "../common"
import importScript from '../components/hooks/ImportScript'

const AddPostAddressForm= (props) =>
{
  // svg操作のjsの読み込み
  importScript(JsDir + '/ajaxzip3.js')

  const dispatch = useDispatch()

    //姓入力
    const [familyNameValue, setFamilyNameValue] = useState()
    const inputFamilyNameValue = useCallback((event) =>
    {
      setFamilyNameValue(event.target.value)
    }, [setFamilyNameValue])

    //名入力
    const [firstNameValue, setFirstNameValue] = useState()
    const inputFirstNameValue = useCallback((event) =>
    {
      setFirstNameValue(event.target.value)
    }, [setFirstNameValue])

    //セイ入力
    const [familyNameFuriganaValue, setFamilyNameFuriganaValue] = useState()
    const inputFamilyNameFuriganaValue = useCallback((event) =>
    {
      setFamilyNameFuriganaValue(event.target.value)
    }, [setFamilyNameFuriganaValue])

    //メイ入力
    const [firstNameFuriganaValue, setFirstNameFuriganaValue] = useState()
    const inputFirstNameFuriganaValue = useCallback((event) =>
    {
      setFirstNameFuriganaValue(event.target.value)
    }, [setFirstNameFuriganaValue])

    //郵便番号入力
    const [potalCodeValue, setPostalcodeValue] = useState()
    const inputPostalCodeValue = useCallback((event) =>
    {
      setPostalcodeValue(event.target.value)
    }, [setPostalcodeValue])

    //住所入力
    const [addressValue, setAddressValue] = useState()
    const inputAddressValue = useCallback((event) =>
    {
      setAddressValue(event.target.value)
    }, [setAddressValue])

    //電話番号入力
    const [telnumberValue, setTelnumberValue] = useState()
    const inputTelnumberValue = useCallback((event) =>
    {
      setTelnumberValue(event.target.value)
    }, [setTelnumberValue])

    //納品書入力
    const [deliverySlipFlagValue, setDeliverySlipFlagValue] = useState('1')
    const inputDeliverySlipFlagValue = useCallback((event) =>
    {
      setDeliverySlipFlagValue(event.target.value)
    }, [setDeliverySlipFlagValue])

    //住所自動入力
    const {AjaxZip3} = window
    const postalAutoInput = ()=>
    {
      AjaxZip3.zip2addr('postal_code_'+props.index,'','address_'+props.index,'address_'+props.index)
      setTimeout(()=>{
        const addressElement = document.getElementsByName('address_'+props.index)
        const addressValue = addressElement[0].value
        setAddressValue(addressValue)
      },100)
    }


  return(
    <form encType="multipart/form-data" method="post" id={'insert_form_'+props.number} onSubmit={(e)=>e.preventDefault()}>
              <table>
                <thead></thead>
                <tbody>
                <tr>
                  <th>お名前<span className="required">必須</span></th>
                  <td>
                    <div className="family_name_area">
                      <span>姓</span>
                      <input
                        type = "text"
                        name = {'family_name_'+props.index}
                        value = {familyNameValue}
                        onChange = {inputFamilyNameValue}
                      />
                      <input type="hidden" name={'myhouse_flag_'+props.index} value="0" />
                    </div>
                    <div className="first_name_area">
                      <span>名</span>
                      <input
                        type = "text"
                        name = {'first_name_'+props.index}
                        value = {firstNameValue}
                        onChange = {inputFirstNameValue}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>フリガナ<span className="required">必須</span></th>
                  <td>
                    <div className="family_name_area">
                      <span>セイ</span>
                      <input
                        type = "text"
                        name = {'family_name_furigana_'+props.index}
                        value = {familyNameFuriganaValue}
                        onChange = {inputFamilyNameFuriganaValue}
                      />
                    </div>
                    <div className="first_name_area">
                      <span>メイ</span>
                      <input
                        type = "text"
                        name = {'first_name_furigana_'+props.index}
                        value = {firstNameFuriganaValue}
                        onChange = {inputFirstNameFuriganaValue}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>郵便番号<span className="required">必須</span></th>
                  <td>
                    〒
                    <input
                      type = "text"
                      name = {'postal_code_'+props.index}
                      value = {potalCodeValue}
                      onChange = {inputPostalCodeValue}
                    />
                    <button className="postal_num_btn" onClick={()=>postalAutoInput()}>郵便番号から検索</button>
                  </td>
                </tr>
                <tr>
                  <th>住所<span className="required">必須</span></th>
                  <td>
                    <input
                      type = "text"
                      name = {'address_'+props.index}
                      value = {addressValue}
                      onChange = {inputAddressValue}
                    />
                  </td>
                </tr>
                <tr>
                  <th>電話番号<span className="required">必須</span></th>
                  <td>
                    <input
                      type = "text"
                      name = {'telnumber_'+props.index}
                      value = {telnumberValue}
                      onChange = {inputTelnumberValue}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    納品書<span className="required">必須</span>
                  </th>
                  <td>
                    <label className="radio_btn_label">
                      <input
                        type="radio"
                        name={'delivery_slip_flag_'+props.index}
                        value={1}
                        checked = {deliverySlipFlagValue === '1'}
                        onChange = {inputDeliverySlipFlagValue}
                      />必要
                    </label>
                    <label className="radio_btn_label">
                      <input
                        type="radio"
                        name={'delivery_slip_flag_'+props.index}
                        value={0}
                        checked = {deliverySlipFlagValue === '0'}
                        onChange = {inputDeliverySlipFlagValue}
                      />不要
                    </label>
                  </td>
                </tr>
              </tbody>
              </table>
            </form>
  )
}
export default AddPostAddressForm